import {writable} from "svelte/store";

export const openVideoModal = writable(false);
export const closingVideoModal = writable(false);
export const videoEmbedCode = writable('');
export const storiesModalVideoEmbedCode = writable('');
export const storiesGalleryIsVisible = writable(false);
export const showFullHeader = writable(true);

export const eventStore = writable(null);
